import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import ApiKeyManager from '../components/ApiKeyManager'

document.addEventListener('turbolinks:load', () => {
  var destination = document.querySelector(".api_key_manager");
  if(destination){
    ReactDOM.render(
      <ApiKeyManager heimdall_server_url={destination.dataset.heimdallServerUrl} />,
      destination,
    )
  }
})

import React from "react"
import PropTypes from "prop-types"
class ApiKeyList extends React.Component {

  constructor(props) {
    super(props);

    this.item = this.item.bind(this);
    this.edit_item = this.edit_item.bind(this);
    this.not_editable_item = this.not_editable_item.bind(this);
    this.copy_button = this.copy_button.bind(this);
  }

  start_edit_mode(key){
    this.props.start_edit_mode(key)
  }

  end_edit_mode(key){
    this.props.end_edit_mode(key)
  }

  reset_and_close(key){
    this.props.reset_and_close(key)
  }

  change_name(key, value){
    this.props.change_name(key, value)
  }

  save_api_key(key){
    this.props.save_api_key(key)
  }

  roll_api_key(key, name) {
    if (confirm("Are you sure, to roll the API-Key \"" + name + "\"? This can't be undone and the current API-Key can't be used anymore.")) {
      this.props.roll_api_key(key)
    }
  }

  enable_api_key(key, name) {
    if (confirm("Are you sure, to enable the API-Key \"" + name + "\" again? Afterwards it can be used again.")) {
      this.props.enable_api_key(key)
    }
  }

  disable_api_key(key, name) {
    if (confirm("Are you sure, to disable the API-Key \"" + name + "\"? The can't be used anymore until you enable it again.")) {
      this.props.disable_api_key(key)
    }
  }

  destroy_api_key(key, name){
    if(confirm("Are you sure, to delete the API-Key \""+name+"\"? This can't be undone.")){
      this.props.destroy_api_key(key)
    }
  }

  time_period_before_for_date(date_string) {
    if (date_string == null) {
      return null
    } else {
      const date = new Date(date_string)
      const now = new Date()
      var delta = Math.abs(now - date) / 1000;
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;
      var years = Math.floor(days / 365, 25);
      days = days % 365
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      var minutes = Math.floor(delta / 60) % 60;
      return { years: years, days: days, hours: hours, minutes: minutes }
    }
  }

  created_before_text(created_before) {
    var created_before_text = ""
    if (created_before.years > 0) {
      created_before_text = `created ${created_before.years} ${(created_before.years == 1) ? "year" : "years"} and ${created_before.days} ${(created_before.days == 1) ? "day" : "days"} ago`
    } else if (created_before.days > 0) {
      created_before_text = `created ${created_before.days} ${(created_before.days == 1) ? "day" : "days"} ago`
    } else if (created_before.hours > 0) {
      created_before_text = `created ${created_before.hours} ${(created_before.hours == 1) ? "hour" : "hours"} ago`
    } else if (created_before.minutes > 5) {
      created_before_text = `created ${created_before.minutes} ${(created_before.minutes == 1) ? "minute" : "minutes"} ago`
    } else {
      created_before_text = "just created "
    }
    return created_before_text
  }

  last_seen_before_text(last_seen_before) {
    if (last_seen_before == null) {
      return "not used yet"
    } else {
      var last_seen_before_text = ""
      if (last_seen_before.years > 0) {
        last_seen_before_text = `last used ${last_seen_before.years} ${(last_seen_before.years == 1) ? "year" : "years"} and ${last_seen_before.days} ${(last_seen_before.days == 1) ? "day" : "days"} ago`
      } else if (last_seen_before.days > 0) {
        last_seen_before_text = `last used ${last_seen_before.days} ${(last_seen_before.days == 1) ? "day" : "days"} ago`
      } else if (last_seen_before.hours > 0) {
        last_seen_before_text = `last used ${last_seen_before.hours} ${(last_seen_before.hours == 1) ? "hour" : "hours"} ago`
      } else if (last_seen_before.minutes > 5) {
        last_seen_before_text = `last used ${last_seen_before.minutes} ${(last_seen_before.minutes == 1) ? "minute" : "minutes"} ago`
      } else {
        last_seen_before_text = "just used"
      }
      return last_seen_before_text
    }
  }

  is_entry_new(created_before) {
    return (created_before.years == 0 && created_before.days == 0 && created_before.hours < 5)
  }

  item(entry) {
    const created_before = this.time_period_before_for_date(entry.created_at)
    const last_seen_before = this.time_period_before_for_date(entry.last_seen_at)
    return (
      <div className={`card api-key-card ${ entry.is_disabled ? "key-disabled" : "" }`} key={entry.key} id={entry.key}>
        <div className="position-absolute d-flex" style={{left: -6, top: -4 }}>
          {this.is_entry_new(created_before) &&
            <span className="translate-middle badge bg-primary mr-1" style={{ color: "white"}}>NEW</span>
          }
          {entry.is_disabled &&
            <span className="translate-middle badge bg-danger mr-1" style={{ color: "white"}}>DISABLED</span>
          }
        </div>
        <div className="card-body">
          <h5 className="card-title">
            {entry.name}&nbsp;
            <button className="btn btn-link small p-1 float-right" onClick={(e) => {
              e.preventDefault()
              this.start_edit_mode(entry.key)
            }}><i className="fa fa-bars"></i></button>
          </h5>
          <p className="api-key-text card-text user-select-all">
            <code>{entry.key}</code>
            {this.copy_button(entry)}
          </p>
        </div>
        <div className="card-footer text-body-secondary d-flex justify-content-between py-1" style={{ paddingHorizontal: "1.25rem" }}>
          <span><small><i>{this.created_before_text(created_before)}</i></small></span>
          <span><small><i>{this.last_seen_before_text(last_seen_before)}</i></small></span>
        </div>
      </div>
    )
  }

  not_editable_item(entry) {
    const created_before = this.time_period_before_for_date(entry.created_at)
    const last_seen_before = this.time_period_before_for_date(entry.last_seen_at)

    return (
      <div className="card api-key-card default-api-key-card" key={entry.key}>
        <div className="position-absolute d-flex" style={{ left: -6, top: -4 }}>
          {this.is_entry_new(created_before) &&
            <span className="translate-middle badge bg-primary mr-1" style={{ color: "white" }}>NEW</span>
          }
          {entry.is_connect_api_key &&
            <span className="translate-middle badge bg-primary mr-1" style={{ color: "white" }}>CONNECT</span>
          }
          {entry.is_disabled &&
            <span className="translate-middle badge bg-danger mr-1" style={{ color: "white" }}>DISABLED</span>
          }
        </div>
        <div className="card-body">
          <h5 className="card-title text-muted">
            <i>{entry.name}</i>
          </h5>
          <p className="api-key-text card-text user-select-all">
            <code>{entry.key}</code>
            {this.copy_button(entry)}
          </p>
        </div>
        <div className="card-footer text-body-secondary d-flex justify-content-between py-1" style={{ paddingHorizontal: "1.25rem" }}>
          <span><small><i>{this.created_before_text(created_before)}</i></small></span>
          <span><small><i>{this.last_seen_before_text(last_seen_before)}</i></small></span>
        </div>
      </div>
    )
  }

  edit_item(entry) {
    const created_before = this.time_period_before_for_date(entry.created_at)
    const last_seen_before = this.time_period_before_for_date(entry.last_seen_at)
    return (
      <div className={`card api-key-card ${entry.is_disabled ? "key-disabled" : ""}`} key={entry.key}>
        <div className="position-absolute d-flex" style={{ left: -6, top: -4 }}>
          {this.is_entry_new(created_before) &&
            <span className="translate-middle badge bg-primary mr-1" style={{ color: "white" }}>NEW</span>
          }
          {entry.saved_data &&
            <span className="translate-middle badge bg-primary mr-1" style={{ color: "white" }}>SAVED</span>
          }
          {entry.is_disabled &&
            <span className="translate-middle badge bg-danger mr-1" style={{ color: "white" }}>DISABLED</span>
          }
        </div>
        <form className="form-inline">
          <div className="card-body">
            <button type="button" className="close inline-block" aria-label="Close" onClick={() => this.reset_and_close(entry.key)} style={{ marginTop: 5, marginRight: 2}}>
              <span aria-hidden="true" className="float-right">&times;</span>
            </button>
            <label htmlFor={"api-key-"+entry.key} className="sr-only">API-Key description</label>
            <input type="text" id={"api-key-"+entry.key} className="form-control mb-2" style={{width: "calc(100% - 50px)"}} placeholder="API-Key description" value={entry.name} onChange={(event) => this.change_name(entry.key, event.target.value)} />
            <p className="api-key-text card-text user-select-all">
              <code>{entry.key}</code>
              {this.copy_button(entry)}
            </p>
            { entry.previous_api_keys != null && entry.previous_api_keys.length > 0 && (
              <p className="card-text">
                Reroll History:<br/>
                {(entry.previous_api_keys || []).map((previous_api_key) => (
                  <code className="text-muted">
                    - <span className="pl-1 text-muted user-select-all">{previous_api_key}</span><br />
                  </code>
                ))}
              </p>
            )}
            <button type="submit" className="btn btn-primary mr-1" disabled={!entry.changed_data} onClick={(e) => {
              e.preventDefault()
              this.save_api_key(entry.key)
            }}>Save</button>
            <button type="submit" className="btn btn-outline-primary mr-1" onClick={(e) => {
              e.preventDefault()
              this.roll_api_key(entry.key, entry.name)
            }}>Roll Key</button>
            <button type="submit" className="btn btn-outline-dark mr-1" onClick={(e) => {
              e.preventDefault()
              this.reset_and_close(entry.key)
            }}>Cancel</button>

            {this.props.entries.length > 1 &&
              <button type="submit" className="btn btn-outline-danger float-right ml-1" disabled={entry.last_seen_at != null} onClick={(e) => {
                e.preventDefault()
                this.destroy_api_key(entry.key, entry.name)
              }}><i className="fa fa-trash"></i> Delete API-Key</button>
            }
            { entry.is_disabled ? (
              <button type="submit" className="btn btn-outline-primary float-right" onClick={(e) => {
                e.preventDefault()
                this.enable_api_key(entry.key, entry.name)
              }}><i className="fa fa-unlock"></i> Enable API-Key</button>
            ) : (
              <button type = "submit" className = "btn btn-outline-danger float-right" onClick = { (e) => {
                e.preventDefault()
                this.disable_api_key(entry.key, entry.name)
              }}><i className="fa fa-lock"></i> Disable API-Key</button>
            )}
          </div>
        </form>
        <div className="card-footer text-body-secondary d-flex justify-content-between py-1" style={{ paddingHorizontal: "1.25rem" }}>
          <span><small><i>{this.created_before_text(created_before)}</i></small></span>
          <span><small><i>{this.last_seen_before_text(last_seen_before)}</i></small></span>
        </div>
      </div>
    )
  }

  copy_button(entry) {
    return (
      <a className="btn btn-link small p-0 px-2" onClick={(e) => {
        e.preventDefault()
        navigator.clipboard.writeText(entry.key)
        e.target.classList.remove("fa-clipboard")
        e.target.classList.add("fa-check")
        setTimeout(() => {
          e.target.classList.remove("fa-check")
          e.target.classList.add("fa-clipboard")
        }, 1000);
      }}><i className="fa fa-clipboard"></i></a>
    )
  }

  render () {
    return (
      <React.Fragment>
        <div className="container-fluid">
          {this.props.entries.sort((a,b) => new Date(a.created_at) - new Date(b.created_at)).map((entry)=>(
            <div key={entry.key} className="row"><div className="col-md">{
              (entry.is_default_transit_test_key === true || entry.is_default_ticketing_test_key === true || entry.is_connect_api_key)?(
                this.not_editable_item(entry)
              ):(
                (entry.edit_mode===true)?(
                  this.edit_item(entry)
                ):(
                  this.item(entry)
                )
              )
            }</div></div>
          ))}
        </div>

      </React.Fragment>
    );
  }
}

ApiKeyList.propTypes = {
  entries: PropTypes.array,
  start_edit_mode: PropTypes.func,
  end_edit_mode: PropTypes.func,
  change_name: PropTypes.func,
  reset_and_close: PropTypes.func,
  save_api_key: PropTypes.func,
  roll_api_key: PropTypes.func,
  enable_api_key: PropTypes.func,
  disable_api_key: PropTypes.func,
  destroy_api_key: PropTypes.func,
};
export default ApiKeyList
